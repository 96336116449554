import React, { useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import Input from "./Input";
import InputDate from "./InputDate";

const Form = ({
  className,
  children,
  buttonClass,
  buttonTitle = "Button Name",
  method,
  apiUrl,
  setErrors = false,
  setSuccess = false,
  resetForm = false,
}) => {
  // Utilise un état pour suivre les éléments de formulaire
  const [formValues, setFormValues] = useState({});

  // Fonction permettant d'envoyer le formulaire
  const handleSubmit = async (event) => {
    event.preventDefault();
    setSuccess(false);
    setErrors(false);

    try {
      const response = await axiosClient({
        url: apiUrl,
        method: method,
        data: formValues,
      });
      // Si la réponse retourne un succès alors on affiche une alerte succès
      if (response.data.success) {
        setSuccess(response.data.message);
      } else {
        // Sinon on affiche une alerte erreur
        setErrors({
          message: response.data.message,
          errorsList: response.data.data,
        });
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
      }
    }
  };

  // Modifie les valeurs des inputs dans le state formValue
  const handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    const multipleElement = target.dataset?.multipleElement

    // Si l'input possède le même name que d'autre input (ex: Ajout employé dans un projet)
    if(multipleElement){
      setFormValues((prev) => {
        // On récupère les valeurs déjà enregistré, si y'a pas on prépare un tableau vide
        let currentValues = prev[name] || [];
        // On ajoute la valeur écrit dans le tableau
        currentValues[target.dataset.elementId - 1] = value;
        return {...prev, [name]: currentValues}
      })

    }else{
      setFormValues(prevState => ({...prevState, [name]: value}))
    }
  };

  // Ajoute les cases coché dans le state formValue
  const handleCheckboxChange = (event) => {
    const { target } = event;
    const { name, value, checked, multiple } = target;
    const toggle = target.dataset?.type

    setFormValues((prevState) => {
      const selectedIds = prevState[name] || [];
      const newSelectedIds = multiple
      // Si il y'a plusieurs checkbox
        ? checked
          ? [...selectedIds, parseInt(value)]
          : selectedIds.filter((selectedId) => selectedId !== parseInt(value))
        : checked
          ? ((toggle) && toggle === 'toggle')
            ? 1
            : parseInt(value)
          : ((toggle) && toggle === 'toggle')
            ? 0
            : undefined
      ;
      return {...prevState, [name]: newSelectedIds}
    })
  };


  // Map les enfants pour les rendre avec différentes props
  const mappedChildren = (children) => { 
    return React.Children.map(children, (child) => {
      // Si l'élement est null ou est un string on le retourne
      if (!React.isValidElement(child)) return child;

      // Si l'élement est un champ input alors on lui modifie c'est props
      if (child.type === Input || child.type === InputDate) {
        const onChangeProp =
          (child.props.type === "checkbox" || child.props.type === "toggle") ? handleCheckboxChange : handleChange;

        const checkedProp = 
          (child.props.type === "checkbox" || child.props.type === "toggle")
            ? (child.props.multiple)
              // Si il en y'a plusieurs on regarde si le array contient la value de l'input
              ? formValues[child.props.name]?.includes(child.props.value)
              : formValues[child.props.name] === parseInt(child.props.value)
            // Si ce n'est pas une checkbox ou un toggle on return rien
            : undefined;
            
        const valueProp = child.props.multipleElement
          // Si un input as l'attribut multiple
          ? (formValues[child.props.name] !== undefined ? formValues[child.props.name][child.props.elementId - 1] : "") || ""
          : (formValues[child.props.name] !== undefined ? formValues[child.props.name] : child.props.value) || "" 

        return React.cloneElement(child, {
          ...child.props,
          key: child.props.name,
          onChangeProp: onChangeProp,
          valueProp: (child.props.type === "checkbox" || child.props.type === "toggle") ? child.props.value : valueProp,
          checkedProp: checkedProp,
          children: mappedChildren(child.props.children),
        });
        
      } else {
        
        // Si l'élèment n'est pas un champ input alors on le retourne
        return React.cloneElement(child, {
          ...child.props,
          children: mappedChildren(child.props.children),
        });
      }
    });
  };

  // Fonction qui ajoute les valeurs par défaut de formValues
  const generateDefaultValueToFormValues = (children) => {
    return React.Children.map(children, (child) => {
      if (!React.isValidElement(child)) return child;

      if (child.type === Input || child.type === InputDate) {
        const { type, name, value, checked, multiple, inputsList } = child.props;

        // Si la checkbox est coché par défaut
        if(type === 'checkbox') handleCheckboxChange({target: {name: name, value: value, checked: checked, multiple: multiple, type: type}})
        if(type === 'toggle') handleCheckboxChange({target: {name: name, value: value, checked: (checked === undefined) ? ((formValues[name]) ? formValues[name] : checked) : checked, multiple: multiple, type: type, dataset: {type: 'toggle'}}})

        // Si un champ n'est pas une checkbox ou un toggle
        if(type != 'checkbox' && type != 'toggle') {
          // Si il  a une value déjà attribué
          if(value) handleChange({target: child.props})

          // Si les champs add_customer / add_benefit / add_employee sont caché (= 0)
          if(!value && (name === 'add_customer' || name === 'add_benefit' || name === 'add_employee')) {
            // On reset leur valeur dans le formValue
            setFormValues((prev) =>( {...prev, [name]: undefined}))
            // Pour chaque inputs liés on reset leur valeur
            inputsList.map((input) => setFormValues((prev) =>( {...prev, [input]: undefined})))
          }
        }
      }

      generateDefaultValueToFormValues(child.props.children)
    })
  }

  useEffect(() => {
    generateDefaultValueToFormValues(children)
  }, [children])

  // Débug
  useEffect(() => {
  }, [formValues])


  useEffect(() => {
    // Si le formulaire doit être reset
    if(resetForm){
      setFormValues({})
    }
  }, [resetForm])

  return (
    <form className={`space-y-4 md:space-y-6 ${className}`} onSubmit={handleSubmit}>
      {mappedChildren(children)}
      <button
        className={`inline-flex items-center text-white focus:outline-none focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 ml-auto bg-darkblue-600 hover:bg-darkblue-700 focus:ring-darkblue-700 border-darkblue-700 ${buttonClass}`}
        type="submit"
      >
        {buttonTitle}
      </button>
    </form>
  );
};

export default Form;