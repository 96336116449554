import React, { useContext, useEffect, useState } from "react";
import Form from "../components/Form/Form";
import Input from "../components/Form/Input";
import { useStateContext } from "../contexts/ContextProvider";
import { NotificationContext } from "../contexts/NotificationContext";

const Profile = () => {
  const { user, getUser } = useStateContext();
  const { addNotification } = useContext(NotificationContext);

  const [errors, setErrors] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (errors || success) {

      if(errors)
        addNotification({message: errors.message, list: errors.errorsList}, 'danger')

      if(success){
        addNotification(success, 'success');
        getUser();
      }
    }
  }, [success, errors]);
  
  return (
    <div id="profile" className="flex">
      {/* Profile form */}
      <div className="w-full md:w-1/3 relative px-6 py-3 mb-6 bg-white border rounded-lg border-gray-150">
        <h1 className="text-3xl mb-4 font-bold">Modifier mon profil</h1>

        <Form
          buttonTitle="Modifier"
          method="post"
          apiUrl={`profile/edit/${user.id}`}
          setErrors={setErrors}
          setSuccess={setSuccess}
        >
          <Input
            type="text"
            label="Nom"
            name="last_name"
            value={user.last_name}
          />
          <Input
            type="text"
            label="Prénom"
            name="first_name"
            value={user.first_name}
          />
          <Input
            type="email"
            label="Email"
            name="email"
            value={user.email}
          />
          <Input
            type="password"
            label="Mot de passe"
            name="password"
          />
        </Form>
      </div>
    </div>
  );
};

export default Profile;
