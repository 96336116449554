import React, { useEffect, useState, useContext } from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import axiosClient from "../../axios-client";
import Form from "../../components/Form/Form";
import Loarder from "../../components/Loader/Loader";
import Input from "../../components/Form/Input";
import Modal from "../../components/Modal/Modal";
import ModalBody from "../../components/Modal/ModalBody";
import ModalHeader from "../../components/Modal/ModalHeader";
import { NotificationContext } from "../../contexts/NotificationContext";


const Administration = () => {
  const { user } = useStateContext();
  const { addNotification } = useContext(NotificationContext);
  const [isloading, setIsLoading] = useState(false);

  const [cagnotte, setCagnotte] = useState([]);
  const [joueurs, setJoueurs] = useState([]);
  const [employeData, setEmployeData] = useState([]);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalPaiement, setShowModalPaiement] = useState(false);
  const [recherche, setRecherche] = useState("");


  const displayJoueurs = async () => {
    setIsLoading(true);
    await axiosClient
      .get(`/employes`)
      .then((res) => {
        res.data.employes.map((employe) => {
          let string = `${employe.last_name} ${employe.first_name}`;
          return employe.name = string.toLowerCase(), employe.CapName = string;
        });
        setJoueurs(res.data.employes);
        setIsLoading(false);
      });
  };

  const displayCagnotte = async () => {
    await axiosClient.get('/cagnotte').then((res) => {
      setCagnotte(res.data.cagnotte);
    })
  }

  const handleEdit = async (id) => {
    await axiosClient.get(`/employes/${id}`).then((res) => {
      setEmployeData(res.data.employe);
      setShowModal(true);
    });
  };

  const handlepaiement = async (id) => {
    await axiosClient.get(`/employes/${id}`).then((res) => {
      setEmployeData(res.data.employe);
      setShowModalPaiement(true);
    });
  };

  const filterHandleChange = async (e) => {
    setRecherche(e.target.value);
  };

  useEffect(() => {
    displayJoueurs();
  }, [success]);

  useEffect(() => {
    displayCagnotte();
  }, [success])

  useEffect(() => {
    if (errors || success) {
      setShowModal(false);
      setShowModalPaiement(false);

      if (errors)
        addNotification({ message: errors.message, list: errors.errorsList }, 'danger')

      if (success)
        addNotification(success, 'success')

      displayJoueurs();
    }
  }, [errors, success]);

  return (
    isloading ? <Loarder /> :
      <div>
        <a className=" mb-10 md:mb-0 absolute top-[150] right-0 p-4 border-2 border-r-0 border-blue-100 rounded-tl-lg rounded-bl-lg" href="/administration/cagnotte">Cagnotte globale : <strong>{cagnotte.montant}</strong> €</a>
        <input type="text" name="recherche" placeholder="Rechercher ..." onChange={(e) => filterHandleChange(e)} className="mt-24 md:mt-0 montserrat rounded-lg focus:border-2 border-blue-100 focus-visible:outline-0 px-2 py-2 border-2 border-transparent drop-shadow-none focus-visible:drop-shadow ease-linear transition-all duration-150 "></input>


        {/* Header Tableau */}
        <div className="  w-full md:w-4/5 mt-8 bg-darkblue-200 rounded-t-lg flex py-3 pl-5 md:text-base text-sm">
          <span className=" uppercase w-28 md:w-64 text-white font-bold">joueur</span>
          <span className=" uppercase w-24 md:w-48 text-white font-bold">à payer</span>
          <span className=" uppercase text-white font-bold">actions</span>
        </div>
        {/* Tableau des joueurs */}

        <Form
          buttonTitle={"Ajouter"}
          method={"post"}
          apiUrl={"employes/cagnotte"}
          setErrors={setErrors}
          setSuccess={setSuccess}
          buttonClassName="mt-0"
          className="space-y-0 md:space-y-0">
          <div className="w-full md:w-4/5 pb-2 bg-white rounded-b-lg" >
            {joueurs.filter(joueur =>
              (joueur.name.includes(recherche) || joueur.CapName.includes(recherche))).map((joueur, i) => (
                <div key={i} className="flex justify-between items-center py-1 border-gray-100 border-t-2">
                  <div className=" w-52">
                    <Input
                      type="checkbox"
                      label={joueur.name}
                      name="joueurs"
                      value={joueur.id}
                      marginBottomContainer={false}
                      multiple={true}
                      id={joueur.id}
                      classLabel={"capitalize"}
                    />
                  </div>

                  <span className=" w-28">{joueur.price} €</span>
                  <span onClick={() => handleEdit(joueur.id)} className=" w-28 hover:cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                    </svg>
                  </span>
                  <span onClick={() => handlepaiement(joueur.id)} className=" w-28 hover:cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                    </svg>
                  </span>
                </div>

              ))}
          </div>
        </Form>


        {/* Modal pour les Amandes */}
        <Modal
          show={showModal}
          onClose={() => setShowModal(false)}
          modalContainerclassName="p-4 sm:p-5"
        >
          <ModalHeader
            title={`Ajouter une amande`}
            modalHeaderContainerclassName="p-0 pb-4"
          />

          <ModalBody modalBodyContainerclassName="px-0 pt-4 pb-0">
            <Form
              buttonTitle={"Ajouter"}
              method={"post"}
              apiUrl={`employes/amande/${employeData.id}`}
              setErrors={setErrors}
              setSuccess={setSuccess}
              buttonClassName="mt-0"
              className="space-y-0 md:space-y-0">
              <Input type="number" name="montant" label={"Montant :"} />
            </Form>
          </ModalBody>

        </Modal>


        {/* Modal pour le paiement */}
        <Modal
          show={showModalPaiement}
          onClose={() => setShowModalPaiement(false)}
          modalContainerclassName="p-4 sm:p-5"
        >
          <ModalHeader
            title={`payer`}
            modalHeaderContainerclassName="p-0 pb-4"
          />

          <ModalBody modalBodyContainerclassName="px-0 pt-4 pb-0">
            <Form
              buttonTitle={"payer"}
              method={"post"}
              apiUrl={`employes/payer/${employeData.id}`}
              setErrors={setErrors}
              setSuccess={setSuccess}
              buttonClassName="mt-0"
              className="space-y-0 md:space-y-0">
              <Input type="number" name="montant" label={"Montant :"} value={employeData ? employeData.price : undefined} />
            </Form>
          </ModalBody>

        </Modal>

        
      </div>

  );
};

export default Administration;