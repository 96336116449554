import React from "react";
import Modal from "./Modal";
import ModalBody from "./ModalBody";
import ModalFooter from "./ModalFooter";
import axiosClient from "../../axios-client";

const ModalSupprimer = ({
  showModal,
  setShowModal,
  id,
  apiUrl,
  setSuccess,
}) => {
  const supprimer = () => {
    setSuccess(false);

    axiosClient
      .delete(`/${apiUrl}/${id}`)
      .then((response) => {
        if (response.data.success) {
          
          if (setSuccess) {
            setSuccess(response.data.message);
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
        }
      });

    setShowModal(false);
  };

  return (
    <Modal
      show={showModal}
      onClose={() => setShowModal(false)}
      modalContainerclassName="p-4 sm:p-5"
    >
      <ModalBody modalBodyContainerclassName="px-0 pt-4 pb-0">
        <div className="bg-white p-4 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <svg
                className="h-6 w-6 text-red-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                />
              </svg>
            </div>
            <div className="mt-3 text-left sm:mt-0 sm:ml-4">
              <h3
                className="text-lg font-medium leading-6 text-gray-900"
                id="modal-title"
              >
                Supprimer l'élément
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  Êtes-vous sûre de vouloir supprimer cet élément ? Toutes les
                  opérations liées à celle-ci seront automatiquement supprimées.
                </p>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter modalFooterContainerClass="justify-end">
        <button
          className="inline-flex w-full items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
          title="Supprimer l'opération"
          onClick={() => {
            supprimer(id);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6 text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
          Supprimer
        </button>

        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => setShowModal(false)}
        >
          Annuler
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalSupprimer;
