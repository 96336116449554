import React, { useEffect, useState } from "react";
import Modal from "../../components/Modal/Modal";
import ModalHeader from "../../components/Modal/ModalHeader";
import ModalBody from "../../components/Modal/ModalBody";

import Input from "../../components/Form/Input";
import Form from "../../components/Form/Form";
import axiosClient from "../../axios-client";

const ActionEmployes = ({
  showAddModal,
  setShowAddModal,
  setErrors,
  setSuccess,
  employe = false,
}) => {
  const [roles, setRoles] = useState([]);

  const displayRoles = async () => {
    await axiosClient.get("/roles").then((res) => {
      setRoles(res.data.roles);
    });
  }

  // Récupère les roles
  useEffect(() => {
    if(showAddModal){
      displayRoles(); 
    }
  }, [showAddModal])

  return (
    <Modal
      show={showAddModal}
      onClose={() => setShowAddModal(false)}
      modalContainerclassName="p-4 sm:p-5"
    >
      <ModalHeader
        title={`${employe ? "Modifier" : "Ajouter"} un Joueur`}
        modalHeaderContainerclassName="p-0 pb-4"
      />

      <ModalBody modalBodyContainerclassName="px-0 pt-4 pb-0">
        <Form
          buttonTitle={employe ? "Modifier" : "Ajouter"}
          method={employe ? "put" : "post"}
          apiUrl={employe ? `employes/${employe.id}` : `employes`}
          setErrors={setErrors}
          setSuccess={setSuccess}
          buttonClassName="mt-0"
          className="space-y-0 md:space-y-0"
          resetForm={true}
        >
          <div className="grid gap-4 sm:grid-cols-2">
            <Input
              type="text"
              label="Nom"
              name="last_name"
              value={employe ? employe.last_name : undefined}
            />
            <Input
              type="text"
              label="Prénom"
              name="first_name"
              value={employe ? employe.first_name : undefined}
            />
          </div>

          <div className="grid gap-4 sm:grid-cols-2">
            <Input
              type="email"
              label="Email"
              name="email"
              value={employe ? employe.email : undefined}
            />
            <Input
              type="text"
              label="Mot de passe"
              name="password"
              value={employe ? employe.password : undefined}
            />
          </div>

          <div className="grid gap-4 sm:grid-cols-2">
            <Input
              type="number"
              label="cagnotte"
              name="price"
              value={employe ? employe.price : undefined}
            />
          </div>

            <Input
              type="select"
              label="Role"
              name="role_id"
              liste={roles}
              value={employe ? employe.role_id : undefined}
            />
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ActionEmployes;
