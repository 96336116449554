import { createBrowserRouter } from "react-router-dom";
import DashboardLayout from "./layouts/DashboardLayout";
import GuestLayout from "./layouts/GuestLayout";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Administration from "./pages/Administration/Administration";
import Profile from "./pages/Profile";
import Employes from "./pages/Employes/Employes";
import Roles from "./pages/Roles/Roles";
import AdministrationLayout from "./layouts/AdministrationLayout";
import Permissions from "./pages/Permissions/Permissions";
import Cagnotte from "./pages/Cagnotte";
import Roulette from "./pages/Roulette";

const router = createBrowserRouter([
  {
    element: <DashboardLayout />,
    children: [
      { index: true, path:"/", element: <Dashboard />, name: "dashboard" },
      { element: <AdministrationLayout />, path: "/administration", children: [
        { index: true, path: "", element: <Administration />, name: "admin" },
        { path: "roles", element: <Roles />, name: "roles.index"},
        { path: "joueurs", element: <Employes />, name: "employes.index" },
        { path: "permissions", element: <Permissions />, name: "permissions.index" },
        { path: "cagnotte", element: <Cagnotte />, name: "cagnotte.index" },
      ] },
      { path: "/profil", element: <Profile />, name: "profil"},
    ],
  },
  {
    path: "/",
    element: <GuestLayout />,
    children: [{ path: "/login", element: <Login /> },
                { path: "/rumble",
                  element: <Roulette /> }
              ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

router.routes[0].element = <DashboardLayout routes={router.routes[0].children} />;

export default router;
