import React, { useEffect, useRef, useState } from "react";
import DropdownListCheckbox from '../Dropdown/DropdownListCheckbox'

const Dropdown = ({
  buttonClass = "",
  buttonTitle = "Button title",
  menuClass = "",
  className,
  children,
  closeOnClick = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef();

  const handleClickOutside = (event) => {
    //  Si le menu est ouvert et existe, et que le clic n'est pas sur la div du menu on le ferme
    if (
      isOpen &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  // Ferme le menu quand on clique en dehors de celui-ci
  useEffect(() => {
    // Ajoute l'évènement du clic de la souris
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Retire l'évènement du clic de la souris au refresh
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className={`w-fit ${className}`} ref={dropdownRef}>
      <button
        className={
          buttonClass
            ? buttonClass
            : "capitalize text-sm text-gray-600 hover:text-gray-700 hover:bg-gray-50 flex items-center"
        }
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {buttonTitle}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`hidden md:block jw-4 h-4 ml-2 ${!isOpen ? "" : "-scale-y-100"}`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>

      {isOpen && (
        <div
          className={`${menuClass} absolute z-10 mt-4 origin-top-right bg-white border border-gray-100 rounded-md shadow-lg`}
        >
          {/* Reprend les composants appelé dans le composant parent et ajoute une fonction pour fermer le menu quand on clique sur un item */}
          {React.Children.map(children, (child) => {
              if(child.type === DropdownListCheckbox){
                return React.cloneElement(child, {
                  onClick: () => {if(closeOnClick) setIsOpen(false);},
                  setIsOpen: setIsOpen
                });
              }else{
                return React.cloneElement(child, {
                  onClick: () => {if(closeOnClick) setIsOpen(false);}
                });
              }
          })}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
