import React, { useContext, useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import Table from "../../components/Table/Table";
import ActionEmployes from "./ActionEmployes";
import { NotificationContext } from "../../contexts/NotificationContext";
import Loader from '../../components/Loader/Loader';

const Employes = () => {
  const { addNotification } = useContext(NotificationContext);

  const [employes, setEmployes] = useState([]);
  const [employeData, setEmployeData] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Status popup modal ajout
  const [showAddModal, setShowAddModal] = useState(false);

  const displayEmployes = async () => {
    setIsLoading(true);
    await axiosClient
      .get(`/employes`)
      .then((res) => {
        setEmployes(res.data.employes);
        setIsLoading(false);
      });
  };

  // Modifie un employé
  const handleEdit = async (id) => {
    await axiosClient.get(`/employes/${id}`).then((res) => {
      setEmployeData(res.data.employe);
      displayEmployes();
      setShowAddModal(true);
    });
  };

  useEffect(() => {
    displayEmployes();
  }, []);

  useEffect(() => {
    if (errors || success) {
      setShowAddModal(false);

      if(errors)
        addNotification({message: errors.message, list: errors.errorsList}, 'danger')

      if(success)
        addNotification(success, 'success')

      displayEmployes();
    }
  }, [errors, success]);

  return (
    <>
    {isLoading && <Loader />}
    <div>
      {/* Bouton ajouter un joueur */}
      <button
        className={`inline-flex items-center text-white focus:outline-none focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 ml-auto bg-darkblue-600 hover:bg-darkblue-700 focus:ring-darkblue-700 border-darkblue-700`}
        onClick={() => {
          setShowAddModal(true);
          setEmployeData(false);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 mr-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        Ajouter un joueur
      </button>

      {/* Modal pour ajouter/éditer un employé */}
      <ActionEmployes
        showAddModal={showAddModal}
        setShowAddModal={setShowAddModal}
        setErrors={setErrors}
        setSuccess={setSuccess}
        employe={employeData ? employeData : false}
      />

      <div className="mt-4 relative">
        <Table
          mockData={employes}
          header={[
            {
              Header: "Nom",
              accessor: "last_name",
            },
            {
              Header: "Prenom",
              accessor: "first_name",
            },
            {
              Header: "Cagnotte",
              accessor: "price",
            },
          ]}
          action={true}
          afficherSupprimer={true}
          apiUrl="employes"
          setSuccess={setSuccess}
          afficherEditer={true}
          editer={handleEdit}
          search={true}
        >
        </Table>
      </div>
    </div>
    </>
  );
};

export default Employes;
