import React from "react";
import { Outlet } from "react-router-dom";
import AdminSidebar from "../components/AdminSidebar";

const AdministrationLayout = () => {
  return (
    <div id="administration" className="flex gap-8 mb-10 md:mb-0">
      <AdminSidebar />

      <div className="w-full">
        <Outlet />
      </div>
    </div>
  );
};

export default AdministrationLayout;