import React, { useContext, useEffect, useState } from 'react';
import axiosClient from '../axios-client';
import Loader from '../components/Loader/Loader';
import Modal from '../components/Modal/Modal';
import ModalHeader from '../components/Modal/ModalHeader';
import ModalBody from '../components/Modal/ModalBody';
import Form from '../components/Form/Form';
import Input from '../components/Form/Input';
import { NotificationContext } from "../contexts/NotificationContext";


const Cagnotte = () => {
    const [cagnotte, setCagnotte] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [showModal , setShowModal] = useState(false);
    const [errors , setErrors] = useState();
    const [success , setSuccess] = useState();
    const { addNotification } = useContext(NotificationContext);

    

    const getCagnotte = async () => {
        setIsLoading(true);
        await axiosClient.get('/cagnotte').then((res) => {
            setCagnotte(res.data.cagnotte);
            setIsLoading(false);
        })
    }

    useEffect(() => {
        getCagnotte();
    }, [])

    useEffect(() => {
        if (errors || success) {
          setShowModal(false);
    
          if (errors)
            addNotification({ message: errors.message, list: errors.errorsList }, 'danger')
    
          if (success)
            addNotification(success, 'success')
    
          getCagnotte();
        }
      }, [errors, success]);

    return (
        <>
            {isLoading ? <Loader /> :
                <section>
                    <div className='flex justify-between'>
                        {cagnotte && 
                        <div className='flex items-center'><span className='p-4 bg-blue-100 rounded-lg mr-4 text-white font-bold uppercase'>Budget Actuel :</span><span className=' text-6xl font-bold'>{cagnotte.montant} €</span> </div>
                        }
                        <span onClick={ () => {setShowModal(true)} } className='p-4 bg-darkblue-100 rounded-lg mr-4 text-white font-bold uppercase hover:cursor-pointer select-none'>Modifier</span>
                    </div>
                    <div>
                        {/* Modal pour les Amandes */}
                        <Modal
                            show={showModal}
                            onClose={() => setShowModal(false)}
                            modalContainerclassName="p-4 sm:p-5"
                        >
                            <ModalHeader
                                title={`Enlever le budget`}
                                modalHeaderContainerclassName="p-0 pb-4"
                            />

                            <ModalBody modalBodyContainerclassName="px-0 pt-4 pb-0">
                                <Form
                                    buttonTitle={"Retirer"}
                                    method={"post"}
                                    apiUrl={`cagnotte/budget`}
                                    setErrors={setErrors}
                                    setSuccess={setSuccess}
                                    buttonClassName="mt-0"
                                    className="space-y-0 md:space-y-0">
                                    <Input type="number" name="montant" label={"Montant :"} />
                                </Form>
                            </ModalBody>

                        </Modal>
                    </div>
                </section>

            }
        </>

    );
};

export default Cagnotte;