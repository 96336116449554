import React, { useEffect, useState } from 'react';
import Form from "../../components/Form/Form";
import Input from '../../components/Form/Input';
import Modal from '../../components/Modal/Modal';
import ModalBody from '../../components/Modal/ModalBody';
import ModalHeader from '../../components/Modal/ModalHeader';

const AddRole = ({showAddModal, setShowAddModal, setErrors, setSuccess, permissions, role = false}) => {
    // Variables pour la gestion des checkboxes
    const [isCheckedAll, setIsCheckedAll] = useState(false);
    const [checkedBox, setCheckedBox] = useState([]);

    // Sélectionne toutes les permissions
    const selectAllPermissions = () => {
        setIsCheckedAll(!isCheckedAll);
        setCheckedBox(permissions.map(permission => permission.id));
        if (isCheckedAll) {
            setCheckedBox([]);
        }
    }

    useEffect(() => {
        if(role){
            setCheckedBox(role.permissions.map((permission) => permission.id ))
            
            if(permissions.length > 0 && role.permissions.length === permissions.length){
                setIsCheckedAll(true)
            }else{
                setIsCheckedAll(false)
            }
        }
    }, [role])

    useEffect(() => {
      if(!showAddModal){
        setIsCheckedAll(false);
        setCheckedBox([]);
      }
    }, [showAddModal])

    return (
        <Modal
        show={showAddModal}
        onClose={() => setShowAddModal(false)}
        modalContainerclassName="p-4 sm:p-5"
      >
        <ModalHeader
          title={`${role ? "Modifier" : "Ajouter"} un role`}
          modalHeaderContainerclassName="p-0 pb-4"
        />

        <ModalBody modalBodyContainerclassName="px-0 pt-4 pb-0">
          <Form
            buttonTitle={(role) ? "Modifier" : "Ajouter"}
            method={(role) ? "put" : "post"}
            apiUrl={(role) ? `roles/${role.id}` : `roles`}
            setErrors={setErrors}
            setSuccess={setSuccess}
            buttonClassName="mt-0"
            className="space-y-0 md:space-y-0"
            resetForm={role ? false : true}
          >
              <Input
                type="text"
                label="Nom"
                name="name"
                value={role ? role.name : undefined}
              />

            <div className="form-group w-full">
              <div className="flex items-center justify-between mb-4">
                <h4>Permissions</h4>

                <div className="checkbox-input__container">
                  <div className="input-content">
                    <span onClick={selectAllPermissions} className="cursor-pointer">Tout {(isCheckedAll) ? 'déselectionner' : 'sélectionner'}</span>
                  </div>
                </div>
              </div>

              <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg sm:flex sm:flex-wrap">
                {permissions.map((permission, i) => (
                  <li
                    key={i}
                    className={`w-1/4 flex`}
                  >
                    <Input
                      type="checkbox"
                      label={permission.name}
                      name="permissions"
                      value={permission.id}
                      marginBottomContainer={false}
                      checked={checkedBox.includes(permission.id)}
                      multiple={true}
                      id={permission.id}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    );
};

export default AddRole;