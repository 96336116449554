import React, { useRef } from "react";
import InputDate from "./InputDate";
import { useState } from "react";
import { useEffect } from "react";

const Input = ({
  label,
  classLabel = "",
  className = "",
  classContainer = "",
  valueProp,
  onChangeProp,
  name,
  id,
  type = "text",
  placeholder = "",
  disabled = false,
  checkedProp = false,
  multiple = false,
  multipleElement = undefined,
  elementId,
  marginBottomContainer = false,
  liste,
  firstOptionLabel = "-- Sélectionner --",
  step = false
}) => {

  const [selectedDate, setSelectedDate] = useState();
  const hiddenRef = useRef();

  useEffect(() => {
    // Quand le champs de la date change on exécute la méthode onChange
    if(selectedDate && hiddenRef.current) onChangeProp({target: {name: hiddenRef.current.name, value: hiddenRef.current.value, multiple: false, type: hiddenRef.current.type}})
  }, [selectedDate])

  const renderInput = () => {
    switch (type) {
      case "checkbox":
        return (
          <div className="flex p-2 pl-3">
            <div className="flex items-center h-5">
              <input
                type={type}
                value={valueProp}
                onChange={onChangeProp}
                disabled={disabled}
                checked={checkedProp}
                name={name}
                id={id}
                multiple={multiple}
                className={`w-4 h-4 text-blue-600 bg-gray-100 rounded accent-blue-500 focus:ring-0 ${className}`}
              />
            </div>
            <div className="ml-2 text-sm">
              <label
                htmlFor={id}
                className={`font-medium text-gray-900 cursor-pointer ${classLabel}`}
              >
                {label}
              </label>
            </div>
          </div>
        );
      case "select":
        return (
          <>
            {label && (
              <label
                className={`block mb-2 text-sm font-medium text-gray-900 ${classLabel}`}
              >
                {label}
              </label>
            )}

            <select
              name={name}
              id={id}
              disabled={disabled}
              value={valueProp}
              onChange={onChangeProp}
              data-multiple-element={multipleElement}
              data-element-id={elementId}
              className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-darkblue-500 focus:border-darkblue-500 block w-full p-2.5 ${className}`}
            >
              <option>{firstOptionLabel}</option>
              {liste.map((item, i) => {
                return (
                  <option key={i} value={item.id}>
                    {item.label ? item.label : item.name}
                  </option>
                );
              })}
            </select>
          </>
        );
      case "number":
        return (
          <>
            {label && (
              <label
                className={`block mb-2 text-sm font-medium text-gray-900 ${classLabel}`}
              >
                {label}
              </label>
            )}
            <input
              type={type}
              value={valueProp}
              onChange={onChangeProp}
              disabled={disabled}
              name={name}
              id={id}
              min="0"
              step={step ? step : 'any'}
              data-multiple-element={multipleElement}
              data-element-id={elementId}
              className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-darkblue-500 focus:border-darkblue-500 block w-full p-2.5`}
            />
          </>
        );
      case "toggle":
        return (
          <>
            <label className="relative inline-flex items-center cursor-pointer">
              <input type="checkbox" onChange={onChangeProp} value={valueProp} data-type="toggle" name={name} checked={checkedProp} className="sr-only peer" />
              <div className={`w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-darkblue-600 ${className}`}></div>
              <span className={`ml-3 text-sm font-medium text-gray-900  ${classLabel}`}>{label} {checkedProp}</span>
            </label>
          </>
        )
      case "date":
        return (
          <>
            <InputDate setSelectedDate={(data) => {setSelectedDate(data);}} label={label} defaultDate={valueProp}/>
            <input type="hidden" id="date" ref={hiddenRef} name={name} defaultValue={selectedDate} />
          </>
        )
      default:
        return (
          <>
            {label && (
              <label
                className={`block mb-2 text-sm font-medium text-gray-900 ${classLabel}`}
              >
                {label}
              </label>
            )}
            <input
              type={type}
              placeholder={placeholder}
              value={valueProp}
              onChange={onChangeProp}
              disabled={disabled}
              name={name}
              data-multiple-element={multipleElement}
              data-element-id={elementId}
              className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-darkblue-500 focus:border-darkblue-500 block w-full p-2.5 ${className}`}
            />
          </>
        );
    }
  };
  return (
    <div className={`${marginBottomContainer ? "mb-6" : ""} ${classContainer}`}>
      {renderInput()}</div>
  );
};

export default Input;
