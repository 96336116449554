import { useEffect, useState } from "react";
import { useAsyncDebounce } from "react-table";

export const GlobalFilter = ({ globalFilter, setGlobalFilter , placeholder}) => {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);


  return (
    <span>
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={ placeholder ? placeholder : `Rechercher ...`}
        className="montserrat rounded-lg focus:border-2 border-blue-100 focus-visible:outline-0 px-2 py-2 border-2 border-transparent drop-shadow-none focus-visible:drop-shadow ease-linear transition-all duration-150 "
      />
    </span>
  );
};