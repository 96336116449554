import React, { useState } from "react";
import { Wheel } from "react-custom-roulette";
import image1 from "../assets/roulette/image1.png"
import image2 from "../assets/roulette/image2.png"
import image3 from "../assets/roulette/image3.png"
import image4 from "../assets/roulette/image4.png"
import image5 from "../assets/roulette/image5.png"
import image7 from "../assets/roulette/image7.png"
import image8 from "../assets/roulette/image8.png"
import image9 from "../assets/roulette/image9.png"

const Roulette = () => {
  const data = [
    { option: "0",image:{uri: image4,sizeMultiplier: 0.6},style: { backgroundColor: "red" } },
    { option: "1",image:{uri: image1,sizeMultiplier: 0.6}, style: { backgroundColor: "#a43ffb" } },
    { option: "2",image:{uri: image3,sizeMultiplier: 0.6},},
    { option: "3",image:{uri: image5,sizeMultiplier: 0.6}, style: { backgroundColor: "#a43ffb" }},
    { option: "4",image:{uri: image2,sizeMultiplier: 0.6},style: { backgroundColor: "red" } },
    { option: "5",image:{uri: image9,sizeMultiplier: 0.6}, style: { backgroundColor: "#a43ffb" } },
    { option: "6",image:{uri: image3,sizeMultiplier: 0.6},style: { backgroundColor: "red" } },
    { option: "7",image:{uri: image8,sizeMultiplier: 0.6}, style: { backgroundColor: "green", textColor: "black" } },
  ];

  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);

  const handleSpinClick = () => {
    if (!mustSpin) {
      const newPrizeNumber = Math.floor(Math.random() * data.length);
      setPrizeNumber(newPrizeNumber);
      setMustSpin(true);
    }
  };

  return (
    <>
    <div className="bg-black flex w-[500px] items-center h-[500px] justify-center rounded-[900px] border-[#e4cb00] border-solid border-[2px] mt-4 mx-auto">
      <Wheel
        mustStartSpinning={mustSpin}
        prizeNumber={prizeNumber}
        data={data}
        outerBorderWidth={2}
        outerBorderColor="#e4cb00"
        radiusLineWidth={2}
        radiusLineColor={"#fff"}
        onStopSpinning={() => {
          setMustSpin(false);
        }}
      />
    </div>
    
    <button onClick={handleSpinClick} className=" text-white">SPIN</button>
    </>
  );
};

export default Roulette;
