import React, { useRef, useState } from "react";
import axiosClient from "../axios-client";
import { useStateContext } from "../contexts/ContextProvider";
import Logo from "../assets/images/logo_fca.png";
import Loader from "../components/Loader/Loader";

const Login = () => {

  const [isLoading, setIsLoading] = useState(false);
  const emailRef = useRef();
  const passwordRef = useRef();
  const { setUser, setAuthToken } = useStateContext();
  const [errors, setErrors] = useState(null);

  const onSubmit = (evt) => {
    setIsLoading(true);
    evt.preventDefault();

    const payload = {
      email: emailRef.current.value,
      password: passwordRef.current.value,
    };

    setErrors(null);

    axiosClient
      .post("/login", payload)
      .then(({ data }) => {
        setUser(data.user);
        setAuthToken(data.authorisation.token);
        setIsLoading(false)
      })
      .catch((err) => {
        const response = err.response;
        console.log(err)
        if (response && response.status === 422) {
          if (response.data.errors) {
            setErrors(response.data.errors);
          } else {
            setErrors({ email: [response.data.message] });
          }
        }
        setIsLoading(false)
      });
  };

  return (
    <div>
      {isLoading ? <Loader /> : ''}
      <section className="bg-gray-50">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div className="flex items-center mb-6 text-2xl font-semibold text-gray-900">
            <img className="w-40" src={Logo} alt="logo" />
          </div>
          <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                Connecter vous à votre compte
              </h1>

              {errors && (
                <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50">
                  {Object.keys(errors).map((key) => (
                    <p key={key}>{errors[key][0]}</p>
                  ))}
                </div>
              )}

              <form onSubmit={onSubmit} className="space-y-4 md:space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    ref={emailRef}
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-rose-600 focus:border-rose-600 block w-full p-2.5"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Mot de passe
                  </label>
                  <input
                    type="password"
                    ref={passwordRef}
                    id="password"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-rose-600 focus:border-rose-600 block w-full p-2.5 "
                    required
                  />
                </div>

                <button
                  type="submit"
                  className="w-full text-white bg-darkblue-600 hover:bg-darkblue-700 focus:ring-4 focus:outline-none focus:ring-darkblue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  Se connecter
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
