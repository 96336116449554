
import React, { useEffect, useState , useContext } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import axiosClient from "../axios-client";
import Table from "../components/Table/Table";
import Loader from "../components/Loader/Loader";


const Dashboard = () => {
  const [joueurs , setJoueurs] = useState([]);
  const [isLoading , setIsLoading] = useState(false);

  const getAllJoueur = async () => {
    setIsLoading(true)
    await axiosClient.get('/employes').then((res) => {
      setJoueurs(res.data.employes);
      setIsLoading(false);
    })
  }

  useEffect(() => {
    getAllJoueur();
  } , [])

  return (
    isLoading ? <Loader /> :
    <>
      <Table
          mockData={joueurs}
          header={[
            {
              Header: "Nom",
              accessor: "last_name",
            },
            {
              Header: "Prenom",
              accessor: "first_name",
            },
            {
              Header: "Cagnotte",
              accessor: "price",
            },
          ]}
          apiUrl="employes"
          search={true}
        >
        </Table>
    </>
  )

}

export default Dashboard;
