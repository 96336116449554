import React from "react";
import { Link, useLocation } from "react-router-dom";

const Sidebar = (props) => {
  //   Récupère la route actuelle
  const pathname = useLocation().pathname;
  return (
    <div className={`mb-8`}>
      {props.list.map((item, i) => {
        return (
          <div
            className="relative flex flex-col items-start justify-center w-full py-6 mb-6 bg-white border rounded-lg border-gray-150"
            key={i}
          >
            <h3 className="hidden px-6 pb-3 text-xs font-semibold leading-4 tracking-wider text-gray-500 uppercase md:block">
              {item.name}
            </h3>

            {item.content.map((content, k) => {
              return (
                <Link
                  to={content.link}
                  className={`flex relative w-full items-center px-6 py-3 text-sm font-medium leading-5 text-gray-600 transition duration-150 ease-in-out rounded-md group hover:text-gray-900 hover:bg-gray-50 focus:outline-none ${pathname === content.link ? "text-gray-900 bg-gray-50" : ""}`}
                  key={k}
                >
                  {content.icon}
                  <span className="hidden truncate md:inline-block">
                    {content.name}
                  </span>
                  <span
                    className={`absolute left-0 block w-1 transition-all duration-300 ease-out rounded-full bg-gray-300 group-hover:top-0 group-hover:h-full ${pathname === content.link ? "top-0 h-full" : "top-1/2 h-0"}`}
                  ></span>
                </Link>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default Sidebar;