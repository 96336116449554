import React from "react";
import Modal from "../../components/Modal/Modal";
import ModalHeader from "../../components/Modal/ModalHeader";
import ModalBody from "../../components/Modal/ModalBody";
import Input from "../../components/Form/Input";
import Form from "../../components/Form/Form";

const ActionPermissions = ({
  showAddModal,
  setShowAddModal,
  setErrors,
  setSuccess,
  permission = false,
}) => {
  return (
    <Modal
      show={showAddModal}
      onClose={() => setShowAddModal(false)}
      modalContainerclassName="p-4 sm:p-5"
    >
      <ModalHeader
        title={`${permission ? "Modifier" : "Ajouter"} une permission`}
        modalHeaderContainerclassName="p-0 pb-4"
      />

      <ModalBody modalBodyContainerclassName="px-0 pt-4 pb-0">
        <Form
          buttonTitle={permission ? "Modifier" : "Ajouter"}
          method={permission ? "put" : "post"}
          apiUrl={permission ? `permissions/${permission.id}` : `permissions`}
          setErrors={setErrors}
          setSuccess={setSuccess}
          buttonClassName="mt-0"
          className="space-y-0 md:space-y-0"
          resetForm={permission ? false : true}
        >
          <Input
            type="text"
            label="Nom"
            name="name"
            value={permission ? permission.name : undefined}
          />
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ActionPermissions;
