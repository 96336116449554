import React from "react";

const ModalFooter = ({children, modalFooterContainerClass}) => {
  return (
    <div className={`flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b ${modalFooterContainerClass}`}>
      {children}
    </div>
  );
};

export default ModalFooter;