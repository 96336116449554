import React from "react";
import { Link, useLocation } from "react-router-dom";
import axiosClient from "../axios-client";
import { useStateContext } from "../contexts/ContextProvider";
import Dropdown from "../components/Dropdown/Dropdown";
import Logo from "../assets/images/logo_fca.png";

const Header = ({ user }) => {
  const { setUser, setAuthToken } = useStateContext();

  //   Récupère la route actuelle
  const pathname = useLocation().pathname;

  //   Déconnect l'utilisateur
  const onLogout = (evt) => {
    evt.preventDefault();

    axiosClient.post("/logout").then(() => {
      setUser({});
      setAuthToken(null);
    });
  };

  return (
    <header id="header" className="relative z-30 ">
      <div className="px-8 mx-auto xl:px-5 container md:relative fixed bottom-0 z-50 bg-white">
        <div className="flex items-center justify-between h-20 border-b-2 border-gray-100 md:justify-start md:space-x-6 md:w-full w-4/5 m-auto">
          {/* GAUCHE MENU MOBILE */}
          <div className="md:hidden">
            <Link to="/administration">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </Link>
          </div>

          {/* LOGO */}
          <div className="inline-flex">
            <Link to={"/"}>
              <img src={Logo} alt="Logo FCA" className=" w-16" />
            </Link>
          </div>

          {/* DROITE MENU MOBILE */}
          <div className="md:hidden">
            <Dropdown
              buttonTitle={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="#000"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              }
              menuClass="right-0 w-56 bottom-20 md:top-0 md:bottom-[unset] "
            >
              <div className="p-2">
                <Link
                  to="/profil"
                  className="block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
                >
                  Profil
                </Link>
              </div>
              <hr />

              <div className="p-2">
                <button
                  className="block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
                  onClick={onLogout}
                >
                  Se déconnecter
                </button>
              </div>
            </Dropdown>
          </div>

          {/* MENU DESKTOP */}
          <div className="md:flex hidden h-full md:flex-1">
            <div className="flex-1 hidden h-full space-x-8 md:flex">
              <Link
                to="/"
                className={`inline-flex items-center px-1 pt-1 text-sm font-medium leading-5 transition duration-150 ease-in-out focus:outline-none border-b-2 text-gray-900 focus:border-darkblue-700 ${
                  pathname === "/"
                    ? "border-darkblue-700"
                    : "border-transparent"
                } `}
              >
                Accueil
              </Link>

              {(user.role.name === "admin" || user.role.name === "manager") && (
                <Link
                  to="/administration"
                  className={`inline-flex items-center px-1 pt-1 text-sm font-medium leading-5 transition duration-150 ease-in-out focus:outline-none border-b-2 text-gray-900 focus:border-darkblue-700 ${
                    pathname.startsWith("/administration")
                      ? "border-darkblue-700"
                      : "border-transparent"
                  } `}
                >
                  Administration
                </Link>
              )}
            </div>

            <div className="flex sm:ml-6 sm:items-center relative">
              <Dropdown buttonTitle={user.first_name} menuClass="right-0 w-56">
                <div className="p-2">
                  <Link
                    to="/profil"
                    className="block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
                  >
                    Profil
                  </Link>
                </div>
                <hr />

                <div className="p-2">
                  <button
                    className="block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
                    onClick={onLogout}
                  >
                    Se déconnecter
                  </button>
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
